@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@media print {
    :root {
        font-size: 3mm;
    }

    body {
        -webkit-print-color-adjust: exact;
        -moz-print-color-adjust: exact;
        -ms-print-color-adjust: exact;
        print-color-adjust: exact;
    }
    
    #navigation {
        display: none;
    }

    #search-bar {
        display: none !important;
    }

    .print {
        display: block;
    }

    .work.weekend {
        background-color: $gray-200;
    }

    .schedules-grid .work div {
        padding: 0;
        font-size: 1.2rem;
    }
}