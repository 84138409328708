$font-family-base: 'Roboto';
$primary: #153975;
$secondary: #f46b16;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import 'react-toastify/dist/ReactToastify.min.css';
@import '~bootstrap/scss/bootstrap';

::-webkit-scrollbar {
    width: 0.5rem;
}

::-webkit-scrollbar:horizontal {
    height: 0.5rem;
}

::-webkit-scrollbar-thumb {
    background-color: $primary;
    border-radius: 0.2rem;
}

::-webkit-scrollbar-track {
    background-color: #cfcfcf;
}

.login-container {
    height: 100vh;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    place-content: center;
    gap: 5rem;
    margin: auto;
    padding: 2rem;

    img {
        max-width: 100%;
    }
}

@media (min-width: 60em) {
    .login-container {
        width: 50vw;
    }
}

#navigation {
    .nav-link {
        color: $primary;
        text-transform: uppercase;
    }

    .active {
        border-bottom: 2px solid $secondary;
    }
}

#sub-nav {
    .active {
        background-color: $secondary;
    }
}

.form-switch {
    .form-check-input {
        cursor: pointer;
    }
}

.btn-shift.is-pinned {
  font-size: 0.85rem;
  padding: 0.2rem;
}

.schedules-grid {
    display: grid;
    grid-template-columns: repeat(var(--grid-columns), 1fr);
    align-content: center;

    .header {
        background-color: $primary;
        color: #fff;
        padding: 0.25rem;
        display: flex;
        place-content: center;
        place-items: center;
        border: 1px solid #fff;
        border-top: 0;
        border-left: 0;
    }

    .header.is-pinned ~ .sticky-top {
        top: 3.5rem;
        height: 3.5rem;
    }

    .row-1 {
        grid-row: 1/2;
    }

    .row-2 {
        grid-row: 2/3;
    }

    .row-3 {
        grid-row: 3/4;
    }

    .row-span-2 {
        grid-row: span 2;
    }

    .row-span-3 {
        grid-row: span 3;
    }

    .col-1 {
        grid-column: 1/2;
    }

    .col-span-2 {
        grid-column: span 2;
    }

    .weekday {
        grid-row: 2/4;
    }

    .row-span-n {
        grid-row: span var(--row-span);
    }

    .cell {
        border: 1px solid $gray-300;
        padding: 0.25rem;
        border-top: 0;
        border-left: 0;
        background-color: #fff;
    }

    .grid-center {
        display: grid;
        place-items: center;
    }

    .work {
        padding: 0;
        display: grid;
        width: 100%;
        height: 100%;
        cursor: pointer;

        &:hover {
            background-color: $gray-300;
        }

        div {
            display: grid;
            padding: 0.25rem;
            place-items: center;
            font-size: 0.8rem;
            transition: ease-in 100ms;

            &:hover {
                transform: scale(0.9);
            }

            &.present {
                border-left: 15px solid green;
            }

            &.not-present {
                border-left: 5px solid red;
            }
        }
    }

    .station-name {
        width: 100%;
        display: flex;
        place-content: center;
        place-items: center;
        font-weight: 500;
        border-left: 1px solid $gray-300;
    }

    .weekend {
        background-color: $gray-200;
    }

    .disabled {
        background-color: $gray-400;
        cursor: not-allowed;
    }

    .bg-gray-500 {
        background-color: $gray-500;
    }

    .day-boundary {
        border-left: 3px solid red;
    }
}

.sticky-left {
    position: sticky;
    left: 0;
}

.btn-secondary {
    color: white;
}

.print {
    display: none;
}